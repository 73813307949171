/*
@File: Exolot - Multipurpose Landing Page HTML5 Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Subscribe Area CSS
** - Welcome Area CSS
** - About Area CSS
** - Services Area CSS
** - Team Area CSS
** - Portfolio Area CSS
** - Features Area CSS
** - Feedback Area CSS
** - Blog Area CSS
** - Work With Us Area CSS
** - Partner Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Right Side Modal CSS
** - Go Top CSS

*/
/*================================================
Default CSS
=================================================*/
$family1: unquote("Poppins");
$family2: unquote("Ubuntu");
$font_family1: "Poppins", sans-serif;
$font_family2: "Ubuntu", sans-serif;

//Aspecir color
$template_color_aspecir: #004c30;
$template_color_light_aspecir: #1cbf84;
$template_hover_color_aspecir: #0d6b49;

@import url("https://fonts.googleapis.com/css?family=#{$family1}:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=#{$family2}:300,300i,400,400i,500,500i,700,700i");

html,
#root,
.App {
  height: 100%;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-display: $font_family1;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
h1,
h2,
h3,
h4,
h5,
h6 p {
  font-family: $font_family2;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pt-40 {
  padding-top: 40px;
}
.w-100p {
  width: 100%;
}
.h-100p {
  height: 100%;
}
a {
  display: inline-block;
  outline: 0 !important;
  text-decoration: none !important;
  transition: 0.4s;
}
p {
  font: {
    weight: 300;
    size: 15px;
  }
  line-height: 1.8;
  color: #999999;
}
img {
  max-width: 100%;
}
.bg-f9f9f9 {
  background: #f9f9f9;
}
.bg-ffd800 {
  background-color: #ffd800;
}

/*btn*/
.btn {
  font: {
    weight: 600;
    size: 15px;
  }
  border: none;
  padding: 10px 33px;
  line-height: 1.5;
  text-transform: capitalize;
  border-radius: 50px;

  &:focus {
    outline: 0;
  }
}
button:focus {
  outline: 0;
}
.btn-primary {
  color: #ffffff;
  &:hover,
  &:focus {
    color: #ffffff;
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    color: #ffffff;
  }
  &.disabled,
  &:disabled {
    color: #ffffff;
    opacity: 1;
    &:hover,
    &:focus {
      color: #ffffff;
      box-shadow: unset;
    }
  }
}

/*owl carousel btn*/
.owl-theme {
  .owl-nav {
    margin-top: 0 !important;

    [class*="owl-"] {
      position: absolute;
      left: 40px;
      top: 45%;
      font-size: 30px !important;
      width: 60px;
      height: 60px;
      text-align: center;
      color: #ffffff !important;
      line-height: 50px !important;
      margin: 0;
      background: rgba(0, 0, 0, 0.5) !important;
      border-radius: 30px !important;
      transition: 0.4s;
    }

    [class*="owl-"]:hover {
      color: #ffffff !important;
    }
    .owl-next {
      left: auto;
      right: 40px;
      cursor: pointer;
    }
    .owl-prev {
      transform: rotate(180deg);
      line-height: 50px;
      cursor: pointer;
    }
  }

  .owl-dots {
    line-height: 0.1;
    margin-top: 0 !important;
    position: relative;
    bottom: 90px;
    display: none;

    .owl-dot {
      span {
        width: 15px !important;
        height: 15px !important;
        margin: 0 5px !important;
        background: #d7d7d7 !important;
        border-radius: 50% !important;
        transition: 0.4s !important;
      }
    }
  }
}
/*form control*/
.form-control {
  height: 45px;
  padding: 15px;
  font-size: 15px;
  color: #000000;
  background: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 5px;

  &:disabled {
    background-color: #eeeeee;
    border-color: #eee;
    cursor: no-drop;
  }
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  // border-color: #eeeeee;
  outline: 0;
  box-shadow: unset;
}
/*section title*/
.section-title {
  text-align: center;
  margin: {
    bottom: 30px;
    top: -8px;
  }
  font-family: $font_family2;

  span {
    display: block;
    text-transform: uppercase;
    margin-bottom: 10px;
    font: {
      size: 14px;
      weight: 700;
    }
  }
  h2 {
    font: {
      size: 30px;
      weight: 300;
    }
    margin: {
      bottom: 15px;
    }

    b {
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    max-width: 535px;
    margin: 0 auto;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 8em;
  height: 8em;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar {
  position: fixed;
  width: 100%;
  height: auto;
  padding: 10px 0;
  left: 0;
  top: 0;
  background-color: transparent !important;
  z-index: 999;

  &.navbar-light.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0;
    background-color: #ffffff !important;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
}
.navbar-light {
  .navbar-brand {
    color: #ffffff;
    font-size: 28px;
    font-weight: 500;
    font-family: $font_family2;
    cursor: pointer;
    width: 250px;

    span {
      font-weight: 700;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .navbar-nav {
    .nav-item {
      padding: 0 14px;

      .nav-link {
        color: #ffffff;
        padding: 0;
        font-family: $font_family2;
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
        span {
          position: relative;
          display: inline-block;
          width: 35px;
          height: 3px;
          right: 0;
          top: -3px;
          transition: 0.4s;

          &::before,
          &:after {
            content: "";
            width: 25px;
            height: 3px;
            position: absolute;
            right: 0;
            transition: 0.4s;
          }
          &::before {
            top: -7px;
          }
          &::after {
            bottom: -7px;
          }
        }

        &:hover span::before,
        &:hover span::after {
          width: 35px;
        }
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.is-sticky {
    .navbar-brand {
      color: #222222;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: #222222;
        }
      }
    }
  }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  height: 100vh;
  overflow: hidden;
  background: {
    color: #dddddd;
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: {
      position: center center;
      image: url(../../assets/img/dots.png);
      repeat: repeat;
    }
    z-index: -1;
  }

  .scroll_down {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 50px;
    width: 30px;
    z-index: 5;
    bottom: 30px;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 20px;
    cursor: pointer;
    animation: movebounce 3s linear infinite;
    outline: none;
    &::before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: "";
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #fff;
      border-radius: 100%;
      animation: scroll_down_btn-animation 2s infinite;
      box-sizing: border-box;
    }
  }
}
button.modal-video-close-btn {
  cursor: pointer;
}
.item-bg-one {
  background-image: url(../../assets/img/test-banner1.jpg);
}
.item-bg-two {
  background-image: url(../../assets/img/test-banner2.jpg);
}
.item-bg-three {
  background-image: url(../../assets/img/test-banner3.jpg);
}
.item-bg-four {
  background-image: url(../../assets/img/test-banner4.jpg);
}
.item-bg-five {
  background-image: url(../../assets/img/test-banner5.jpg);
}
.hero-content {
  text-align: center;
  max-width: 940px;
  margin: 50px auto 0;

  h5 {
    color: #ffffff;
    text-transform: uppercase;
    font: {
      size: 20px;
      weight: 300;
    }
    margin: 0;
  }
  h1 {
    color: #ffffff;
    font: {
      size: 70px;
      weight: 700;
    }
    margin: 15px 0;
    text-transform: uppercase;
  }
  p {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .video-btn {
    font: {
      family: $font_family2;
      weight: 500;
      size: 15px;
    }
    color: #ffffff !important;
    margin-left: 7px;
    text-transform: capitalize;
    background: transparent;
    border: none;
    cursor: pointer;

    i {
      font-size: 38px;
      position: relative;
      top: 11px;
      margin-right: 7px;
    }
  }

  .btn {
    margin-right: 7px;
  }
}
.opacityZero {
  opacity: 0;
}
.opacityOne {
  opacity: 1;
}
.creative-box1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;

  &::before {
    content: "";
    border-right: 50vw solid #ffffff;
    border-top: 60px solid transparent;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 0;
    width: 0;
  }
  &::after {
    content: "";
    position: absolute;
    border-left: 50vw solid #ffffff;
    border-top: 60px solid transparent;
    bottom: 0;
    height: 0;
    left: 0;
    width: 0;
  }
}
.animation-box1 {
  position: absolute;
  left: 100px;
  bottom: 100px;
  animation: movebounce 3s linear infinite;
}
.animation-box2 {
  position: absolute;
  right: 150px;
  top: 150px;
  animation: movebounce 3s linear infinite;
}
.animation-box3 {
  position: absolute;
  left: 100px;
  top: 100px;
  animation: movebounce 3s linear infinite;
}
.animation-box4 {
  position: absolute;
  right: -260px;
  bottom: -260px;
  animation: movebounce 3s linear infinite;
}
.animation-box5 {
  position: absolute;
  right: -150px;
  bottom: -150px;
  z-index: -1;
}
.animation-box6 {
  position: absolute;
  right: 5px;
  bottom: 15px;
  z-index: -1;
  animation: movebounce 3s linear infinite;
}
.animation-box7 {
  position: absolute;
  left: 50%;
  bottom: 50px;
  z-index: -1;
  animation: movebounce 3s linear infinite;
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes scroll_down_btn-animation {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes scroll_down_btn-animation {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.wave_box {
  height: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  .wave {
    background: url(../../assets/img/wave.png) repeat-x;
    position: absolute;
    bottom: 0;
    width: 6400px;
    left: 0;
    height: 90px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    &:nth-of-type(2) {
      bottom: -30px;
      animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
        swell 7s ease -1.25s infinite;
      opacity: 1;
    }
  }
}
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  padding-top: 50px;
  font-family: $font_family2;
  padding-bottom: 50px;
  position: relative;

  p {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 24px;
  }
  position: relative;

  .form-control {
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 30px;
    color: #ffffff;

    &::placeholder {
      color: #ffffff;
    }
  }

  button {
    position: absolute;
    right: 10px;
    top: 0;
    height: 45px;
    width: 75px;
    background: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 25px;
    cursor: pointer;
    line-height: 50px;
  }
  .validation-danger,
  .validation-success {
    color: #ffffff;
    margin-top: 10px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 25px;
    background: {
      image: url(../../assets/img/border.png);
      position: center center;
      repeat: repeat;
      size: contain;
    }
    transform: rotate(540deg);
  }
}

/*================================================
Welcome Area CSS
=================================================*/
.single-box {
  text-align: center;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  padding: 30px 20px;
  background: #ffffff;
  position: relative;
  transition: 0.4s;

  i {
    font-size: 50px;
    color: #999999;
    transition: 0.4s;
  }
  h3 {
    color: #212529;
    font: {
      size: 20px;
      weight: 500;
    }
    margin: {
      bottom: 15px;
      top: 20px;
    }
  }
  p {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    border-radius: 0 0 5px 5px;
    left: 0;
    bottom: 0;
    transition: 0.4s;
  }
  &:hover::before {
    width: 100%;
  }
  &:hover {
    transform: translateY(-10px);
  }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  overflow: hidden;
  z-index: 1;

  .row {
    position: relative;
  }

  .animation-box2 {
    right: 80px;
    top: 50px;
  }
}
.about-image {
  position: absolute;
  left: -90px;
  top: -50px;
}
.about-title {
  h3 {
    font-size: 30px;
    margin: 0 0 10px;
    font-weight: 400;
  }
  h5 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 300;
    margin: 0 0 15px;
    span {
      font-weight: 400;
    }
  }
  p {
    margin-bottom: 0;
  }
}
.our-skill {
  margin-top: 25px;

  .single-progress {
    .progress-title {
      font-size: 16px;
      margin: 0 0 8px;
    }
    .progress {
      height: 10px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: none;
      margin-bottom: 20px;
      overflow: visible;

      .progress-bar {
        box-shadow: none;
        position: relative;
        border-radius: 10px;
        animation: animate-positive 2s;
      }
      .progress-value {
        font-size: 15px;
        font-weight: 400;
        color: #222222;
        font-family: $font_family2;
        position: absolute;
        top: -28px;
        right: 0;
      }
    }
    &:nth-child(4) .progress {
      margin-bottom: 0;
    }
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.our-mission {
  p {
    margin: {
      bottom: 0;
      top: 20px;
    }
  }
}
.why-choose-us {
  text-align: center;
  background: #ffffff;
  margin-top: 20px;

  .single-choose {
    padding: 20px 15px;

    i {
      font-size: 35px;
      color: #777777;
      transition: 0.4s;
    }
    h5 {
      font-size: 16px;
      font-weight: 500;
      margin: {
        bottom: 0;
        top: 8px;
      }
    }
  }

  .col-lg-4:nth-child(1) .single-choose {
    border-bottom: 1px solid #eeeeee;
  }
  .col-lg-4:nth-child(2) .single-choose {
    border: {
      bottom: 1px solid #eeeeee;
      right: 1px solid #eeeeee;
      left: 1px solid #eeeeee;
    }
  }
  .col-lg-4:nth-child(3) .single-choose {
    border-bottom: 1px solid #eeeeee;
  }
  .col-lg-4:nth-child(5) .single-choose {
    border: {
      right: 1px solid #eeeeee;
      left: 1px solid #eeeeee;
    }
  }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 70px;
  background: {
    image: url(../../assets/img/bg.png);
    attachment: fixed;
    repeat: repeat;
  }
}
.single-services {
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: 0.4s;
  text-align: center;
  cursor: pointer;

  a {
    padding: 30px 20px;
    height: 100%;
    width: 100%;
  }

  img {
    width: 50px;
  }
  h3 {
    color: #212529;
    font: {
      size: 20px;
      weight: 500;
    }
    margin: {
      bottom: 15px;
      top: 20px;
    }
  }
  p {
    margin-bottom: 0;
  }
  .bg-number {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 54px;
    opacity: 0.25;
    transition: 0.4s;
  }

  &:hover .bg-number {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  position: relative;
  overflow: hidden;

  .animation-box4 {
    right: -300px;
    bottom: -300px;
  }
}
.single-team {
  text-align: center;

  ul {
    margin: 0;
    list-style-type: none;
    display: inline-block;
    position: relative;
    top: -20px;
    padding: 6px 30px;
    border-radius: 30px;

    li {
      display: inline-block;

      a {
        color: #ffffff;
        font-size: 15px;
        margin: 0 -3px;
        width: 33px;
        border-radius: 50%;
        border: 1px solid transparent;
        height: 33px;
        text-align: center;
        line-height: 33px;

        &:hover {
          border-color: #ffffff;
          background: #ffffff;
        }
      }
    }
  }
  h3 {
    font: {
      weight: 500;
      size: 20px;
    }
    margin: {
      top: 6px;
      bottom: 6px;
    }
  }
  span {
    display: block;
    font: {
      size: 16px;
      weight: 500;
    }
    color: #999999;
  }
}

/*================================================
Portfolio Area CSS
=================================================*/
.portfolio-area {
  padding-bottom: 60px;
}
.single-work {
  margin-bottom: 40px;
  .work-image {
    display: block;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      transition: 0.4s;
    }
    &:hover::before,
    &:focus::before {
      width: 100%;
    }
    .popup-btn {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      right: 0;
      cursor: pointer;
      color: #ffffff;
      border: 2px solid #ffffff;
      width: 45px;
      margin: 0 auto;
      height: 45px;
      line-height: 45px;
      border-radius: 50%;
      margin-top: 0;
      opacity: 0;
      visibility: hidden;
      &:hover {
        background: #ffffff;
      }
    }
    &:hover .popup-btn,
    &:focus .popup-btn {
      opacity: 1;
      visibility: visible;
      margin-top: -15px;
    }
  }
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 25px 0 0;
    line-height: 12px;
  }
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0.2;
    width: 100%;
    background: url(../../assets/img/white-waves.png);
  }
}
.features-img img {
  border-radius: 10px;
}
.single-features {
  position: relative;
  margin-bottom: 30px;
  padding-left: 90px;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    font-size: 30px;
    transition: 0.4s;
  }
  &:hover .icon {
    background: transparent;
    color: #ffffff;
  }
  h3 {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 15px;
  }
  p {
    color: #fff;
    margin-bottom: 0;
    font-weight: 400;
  }
  &:nth-child(3) {
    margin-bottom: 0;
  }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
  position: relative;
  overflow: hidden;
  .feedback-container {
    a {
      width: 100%;
    }
  }
}
.single-feedback {
  background: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 5px;
  margin-top: 25px;
  z-index: 1;
  position: relative;
  transition: 0.4s;

  .img {
    background-size: contain;
    width: 100%;
    height: 235px;
    background-repeat: no-repeat;
    background-position: center;
  }

  h4,
  small {
    color: #212529;
    transition: 0.4s;
  }
  .client-info {
    position: relative;
    padding-left: 118px;
    margin-bottom: 20px;

    .img {
      position: absolute;
      left: 0;
      top: -45px;
      width: 100px;
      img {
        border-radius: 0 20px 0 0;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 500;
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    span {
      display: block;
      font-size: 14px;
      color: #828893;
      margin-top: 8px;
      transition: 0.4s;
    }
  }
  p {
    transition: 0.4s;
    margin-bottom: 0;
    overflow: hidden;
  }
  &:hover p,
  &:hover h4,
  &:hover small {
    color: #fff;
  }
  i {
    font-size: 104px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #6f8ba4;
    z-index: -1;
    margin-top: -6px;
    margin-left: -70px;
    opacity: 0.07;
    transition: 0.4s;
  }
  &:hover i {
    color: #ffffff;
    opacity: 0.14;
  }
}
/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  padding-bottom: 70px;
}
.single-blog-post {
  margin-bottom: 30px;

  .post-thumbnail {
    overflow: hidden;

    a {
      display: block;
      img {
        transition: 0.4s;
      }
    }
  }

  &:hover .post-thumbnail a img {
    transform: scale(1.3);
  }

  .post-content {
    margin-top: 25px;
    h3 {
      font: {
        size: 20px;
        weight: 700;
      }
      line-height: 30px;
      margin-bottom: 15px;
      a {
        color: #000000;
      }
    }
    .read-more {
      font-size: 15px;
      color: #777777;
    }
  }
}

/*================================================
Work With Us Area CSS
=================================================*/
.work-with-us {
  z-index: 1;
  text-align: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    opacity: 0.2;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/img/regal.png);
    z-index: -1;
  }
  h3 {
    font: {
      size: 40px;
      weight: 700;
    }
    color: #ffffff;
    margin: {
      bottom: 12px;
      top: -7px;
    }
  }
  span {
    font-size: 20px;
    color: #ffffff;
    display: block;
    margin-bottom: 25px;
  }
  .btn-primary {
    color: #71c21b;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    &:hover,
    &:focus {
      color: #ffffff;
      background: transparent;
    }
  }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  text-align: center;
  overflow: hidden;
  position: relative;
  .item a {
    display: block;
    position: relative;
    z-index: 1;
  }
  .owl-carousel .owl-item img {
    width: 140px;
  }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-box {
  min-height: 133px;
  padding: {
    left: 115px;
    right: 30px;
    top: 25px;
    bottom: 25px;
  }
  position: relative;

  .icon {
    position: absolute;
    left: 25px;
    top: 30px;
    width: 70px;
    height: 70px;
    line-height: 68px;
    font-size: 35px;
    text-align: center;
    border-radius: 50%;
    transition: 0.4s;
  }
  &:hover .icon {
    color: #ffffff;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  p {
    margin: 0;
    line-height: 25px;
    font-weight: 400;
    a {
      color: #777777;
    }
  }
}
.get-in-touch {
  margin-top: 40px;
  h3 {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: inline-block;
      a {
        margin-right: 5px;
      }
    }
  }
}

#contactForm {
  .list-unstyled li {
    color: red;
  }
  #msgSubmit {
    margin: 0;
    &.text-left {
      margin-top: 20px;
      font-size: 18px;
    }
  }
}

.sendingMsg {
  background-color: #f0ad4e;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 40px;

  span {
    color: #fff;
  }
}

.contactMsg {
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 40px;

  span {
    color: #fff;
  }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding: 30px 0;
  background: #121314;
  h6 {
    color: #ddd;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    color: #d5d5d5;
  }
  ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    position: relative;
    font-size: 14px;
    padding-left: 17px;
    &::before {
      content: "";
      position: absolute;
      left: 5px;
      top: 5px;
      background: #ffffff;
      width: 2px;
      height: 12px;
    }
    &:first-child::before {
      display: none;
    }
    a {
      color: #d5d5d5;
    }
  }
}

/*================================================
Right Side Modal CSS
=================================================*/
.modal.right {
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 520px;
    height: 100%;
    transform: translate3d(0%, 0, 0);

    .modal-content {
      height: 100%;
      overflow-y: auto;
      border-radius: 0;
      border: none;

      .modal-body {
        padding: 20px;

        p {
          margin-top: 20px;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
        }

        .modal-contact-info {
          margin-top: 30px;
          margin-bottom: 20px;

          li {
            position: relative;
            padding-left: 45px;
            font-size: 18px;
            margin-bottom: 25px;

            b {
              display: block;
            }
            i {
              position: absolute;
              left: 0;
              top: 0;
              font-size: 35px;
            }
          }
        }

        .social-links {
          li {
            display: inline-block;

            a {
              margin-right: 10px;
              font-size: 18px;
            }
          }
        }

        .logo {
          width: 200px;
          a {
            color: #283a5e;
            text-transform: uppercase;
            font-size: 30px;
            font-weight: 900;
          }
        }
      }
      .modal-header {
        border-bottom-color: transparent;
        background-color: transparent;
        padding: 20px 30px 0;

        .close {
          color: #000000;
          font-size: 35px;
          outline: 0 !important;
          transition: 0.4s;

          &:not(:disabled):not(.disabled):focus,
          &:not(:disabled):not(.disabled):hover {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }
  &.fade {
    .modal-dialog {
      right: -420px;
      transition: opacity 0.3s linear, right 0.3s ease-out;
    }
    &.show {
      .modal-dialog {
        right: 0;
      }
    }
  }
}

/*================================================
Got Top CSS
=================================================*/
.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  transition: 0.4s !important;
}

/*================================================
Internal Page
=================================================*/
.internal-page {
  height: 100%;
  .main-container {
    background-color: #fff;
    position: relative;
    min-height: 100%;
  }

  section {
    padding-top: 50px;
    padding-bottom: 320px;
  }

  .navbar {
    position: inherit;
    &.navbar-light {
      .navbar-nav {
        .nav-item {
          .nav-link {
            &:hover {
              color: #fff;
              opacity: 0.6;
            }
            span {
              background: #fff;
              &::before,
              &:after {
                background: #fff;
              }
            }
          }
        }
      }
    }
  }

  .pagination {
    margin-top: 20px;
    li {
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background-color: #f3f3f3;
      }
      a {
        padding: 0 10px;
      }
      &.active {
        a {
          color: #fff !important;
        }
      }
    }
  }

  .footer-area {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

/*================================================
News Page
=================================================*/
.news-page {
  .news-aside-list {
    background-color: #f3f3f3;
    padding: 5px 8px;

    .new-aside-item {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      p {
        overflow: hidden;
      }
    }
  }

  .news-main {
    .news-title {
      color: $template_color_aspecir;
      margin-bottom: 0;
    }
  }
}

/*================================================
Plans Page
=================================================*/
.plans-page {
  .plans-banner {
    margin-bottom: 30px;
  }
  .plans-aside-list {
    .plan-aside-item {
      background-color: #f3f3f3;
      margin-bottom: 4px;
      &.react-sanfona-item-expanded,
      &:hover {
        color: #fff;
        transition: 0.5s;
      }
      h3 {
        font-family: $family2;
        font-size: 1.2rem;
        padding: 8px 10px;
      }
      .react-sanfona-item-body-wrapper {
        padding: 5px 5px 10px;
        .plan-aside-subitem {
          cursor: pointer;
          padding: 2px 0 2px 30px;
          &:hover {
            transition: 0.2s;
          }
        }
      }
    }
  }
}

/*================================================
Aspecir Page
=================================================*/
.btn-primary {
  background-color: $template_color_aspecir;
  border-color: $template_color_aspecir;

  &:hover,
  &:focus {
    background: $template_hover_color_aspecir;
    border-color: $template_hover_color_aspecir;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    background: $template_color_aspecir;
    border-color: $template_color_aspecir;
  }
  &.disabled,
  &:disabled {
    background-color: #a7a7a7;
    border-color: #a7a7a7;
    &:hover,
    &:focus {
      cursor: no-drop;
    }
  }
}
.owl-theme {
  .owl-nav {
    [class*="owl-"]:hover {
      background: $template_color_aspecir !important;
    }
  }
  .owl-dots {
    .owl-dot {
      &:hover span,
      &.active span {
        background: $template_color_aspecir !important;
      }
    }
  }
}
.section-title {
  span {
    color: $template_color_aspecir;
  }
}
.loader {
  border: 1.1em solid $template_color_aspecir;
  border-left: 1.1em solid $template_hover_color_aspecir;
}
.navbar-light {
  .navbar-brand {
    span {
      color: $template_color_aspecir;
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        &:hover {
          color: $template_color_light_aspecir;
        }

        span {
          background: $template_color_light_aspecir;
          &::before,
          &:after {
            background: $template_color_light_aspecir;
          }
        }

        &.active {
          color: $template_color_light_aspecir;
        }
      }

      &.active .nav-link {
        color: $template_color_light_aspecir;
      }
    }
  }
  &.is-sticky {
    .navbar-nav {
      .nav-item {
        .nav-link {
          &:hover {
            color: $template_color_aspecir;
          }
          &.active {
            color: $template_color_aspecir;
          }
        }

        &.active .nav-link {
          color: $template_color_aspecir;
        }
      }
    }
  }
}
.hero-content {
  h1 {
    span {
      color: $template_color_light_aspecir;
    }
  }
  .video-btn {
    &:hover,
    &:focus {
      color: $template_color_aspecir !important;
    }
  }
}
.subscribe-area {
  background: $template_color_aspecir;

  button {
    color: $template_color_aspecir;
  }
}
.single-box {
  &:hover i {
    color: $template_color_aspecir;
  }

  &::before {
    background: $template_color_aspecir;
  }
}
.about-title {
  h5 {
    span {
      color: $template_color_aspecir;
    }
  }
}
.our-skill {
  .single-progress {
    .progress {
      .progress-bar {
        background: $template_color_aspecir;
      }
    }
  }
}
.why-choose-us {
  .single-choose {
    &:hover i {
      color: $template_color_aspecir;
    }
  }
}
.single-services {
  &:hover i {
    color: $template_color_aspecir;
  }
  .bg-number {
    color: $template_color_aspecir;
  }
}
.single-team {
  ul {
    background: $template_color_aspecir;

    li {
      a {
        &:hover {
          color: $template_color_aspecir;
        }
      }
    }
  }
}
.single-work {
  .work-image {
    &::before {
      background: $template_color_aspecir;
    }
    .popup-btn {
      &:hover {
        color: $template_color_aspecir;
      }
    }
  }
}
.features-area {
  background: $template_color_aspecir;
}
.single-features {
  .icon {
    color: $template_color_aspecir;
  }
}
.single-feedback {
  &:hover {
    background: $template_color_aspecir;
  }
}
.tab-slider-nav {
  .tab-slider {
    .nav-tabs {
      a {
        &.active {
          background: $template_color_aspecir;
        }
      }
    }
  }
}
.work-with-us {
  background: {
    color: $template_color_aspecir;
  }
}
.contact-box {
  .icon {
    color: $template_color_aspecir;
    border: 1px solid $template_color_aspecir;
  }
  &:hover .icon {
    background: $template_color_aspecir;
    color: #fff;
  }
  p {
    a {
      &:hover {
        color: $template_color_aspecir;
      }
    }
  }
}
.get-in-touch {
  ul {
    li {
      a {
        color: $template_color_aspecir;
      }
    }
  }
}
.contactMsg {
  background-color: $template_color_aspecir;
}
.footer-area {
  li {
    a {
      &:hover {
        color: $template_color_aspecir;
      }
    }
  }
}
.modal.right {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .modal-contact-info {
          li {
            i {
              color: $template_color_aspecir;
            }
          }
        }
        .social-links {
          li {
            a {
              color: $template_color_aspecir;
            }
          }
        }

        .logo {
          a {
            span {
              color: $template_color_aspecir;
            }
          }
        }
      }
      .modal-header {
        .close {
          &:not(:disabled):not(.disabled):focus,
          &:not(:disabled):not(.disabled):hover {
            color: $template_color_aspecir;
          }
        }
      }
    }
  }
}
.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  background-color: $template_color_aspecir !important;
}

/*================================================
Aspecir Internal Page
=================================================*/
.internal-page {
  .navbar {
    background-color: $template_color_aspecir !important;
  }

  .pagination {
    li {
      a {
        color: $template_color_aspecir;
      }
      &.active {
        background-color: $template_color_aspecir;
      }
    }
  }
}

/*================================================
Aspecir Plans Page
=================================================*/
.plans-page {
  .plans-aside-list {
    .plan-aside-item {
      &.react-sanfona-item-expanded,
      &:hover {
        background-color: $template_color_aspecir;
      }
      .react-sanfona-item-body-wrapper {
        .plan-aside-subitem {
          &:hover,
          &.active {
            background-color: $template_hover_color_aspecir;
          }
        }
      }
    }
  }

  .form-control {
    padding: 8px 15px;
  }

  .input-group-text {
    background-color: $template_hover_color_aspecir;
    color: #fff;
  }

  .rangeslider-div {
    position: relative;
  }

  .rangeslider {
    position: absolute;
    width: 100%;
    top: 30px;
  }

  .card-header {
    background-color: transparent;
    border-bottom: none;
  }

  .card-body {
    .card-title {
      font-size: 4rem;
      margin-bottom: 30px;
    }

    button {
      margin-top: 10px;

      &:nth-of-type(2) {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .card-footer {
    background-color: transparent;
    border-top: none;
  }
}

/*================================================
Aspecir Aporte Page
=================================================*/
.aporte-page {
  section {
    padding-bottom: 182px;

    h4 {
      font-size: 1.2rem;
    }

    form {
      label {
        font-size: 0.85rem;
      }
    }

    table {
      border: 1px solid #dee2e6;
      thead {
        background-color: #9fd0bd;
        tr {
          th {
            border-bottom-width: 1px;
            font-size: 0.85rem;
            padding: 0.2rem;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 0.85rem;
            padding: 3px 15px;
          }
        }
      }

      tfoot {
        tr {
          td {
            // font-size: 0.85rem;
            font-weight: 700;
            padding: 3px 15px;
          }
        }
      }
    }

    .container-faq {
      background-color: #01583d;
      padding: 50px 0;

      h2 {
        color: #fff;
        font-size: 1.85rem;
        margin-bottom: 20px;
      }

      .faq-list {
        .faq-item {
          margin-bottom: 5px;

          h3 {
            background-color: #fff;
            color: #339933;
            font-size: 1rem;
            font-weight: 400;
            padding: 10px 15px;
          }

          .faq-subitem {
            border: 1px solid #fff;
            color: #fff;
            padding: 10px 15px;
          }
        }
      }
    }
  }
}

/*================================================
Component Section Certificate Page
=================================================*/
.section-certificate-page:hover {
  background-color: #0a6443e8 !important;
}

@media (max-width: 992px) {
  .section-certificate-page {
    margin-top: 20px !important;
  }
}

/*================================================
Aspecir Certificate Page
=================================================*/

.container-fluid-certificate-component {
  display: grid;
  background-color: #1cbf84;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: minmax(380px, max-content);
  justify-content: start;
  align-items: center;
  position: relative;
  column-gap: 30px;
  row-gap: 0px;

  .content-certificate-component {
    color: white;
    flex-direction: column;
    align-items: center;
    grid-column: 2;

    .title-certificate-component {
      font-size: 50px;
      line-height: 70%;
      color: white;
    }
  }
}

.form_certificate_component {
  .form-control {
    border: 1px solid #bdbdbd !important;
    border-radius: 8px;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .form-control-no-spinner::-webkit-inner-spin-button,
  .form-control-no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Estilos adicionais para compatibilidade com diferentes navegadores */
  .form-control-no-spinner {
    -moz-appearance: textfield;
  }
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .container-fluid-certificate-component {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .title-certificate-component {
    margin-right: 20px;
    font-size: 45px !important;
    color: white;
  }
}

.schedule_config {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 1000;
}
